import {Component, Input, OnInit} from "@angular/core";
import {formatEuropeanDate, formatUSDate} from "../../../utils/date";
import {bibtexEscape} from "../../../utils/bibtex";
import {TranslateService} from "@ngx-translate/core";
import * as _ from "lodash";
import {DisplayService} from "../../display.service";
import {CitationInfo} from "../../../apina-digiweb";
import {convert, DateTimeFormatter, LocalDate} from "@js-joda/core";
import { Router } from '@angular/router';

@Component({
    selector: "app-citation-info",
    template: `
        <div class="citation-info-content" [class.mobile]="displayService.isMobile">
            <ngb-tabset>
                <ngb-tab [title]="'citation.textual-reference' | translate">
                    <div class="kk-tab-content" *ngbTabContent>
                        <textarea class="citable-content" rows="6" [style.width.ch]="textualWidth" [(ngModel)]="textualReference"></textarea>
                    </div>
                </ngb-tab>

                <ngb-tab [title]="'Wikipedia'">
                    <div class="kk-tab-content position-relative" *ngbTabContent>
                        <nav class="wikipedia-locales">
                            <a href="" (click)="$event.preventDefault(); wikipediaLocale = locale"
                               [ngClass]="{'active': wikipediaLocale == locale}"
                               *ngFor="let locale of wikipediaLocales">{{locale}}</a>
                        </nav>

                        <textarea class="citable-content" rows="15" [style.width.ch]="wikiWidth" [(ngModel)]="wikipediaReference"></textarea>
                    </div>
                </ngb-tab>

                <ngb-tab [title]="'BibTeX'">
                    <div class="kk-tab-content" *ngbTabContent>
                        <textarea class="citable-content" rows="15" [style.width.ch]="bibtexWidth" [(ngModel)]="bibtexReference"></textarea>
                    </div>
                </ngb-tab>

                <ngb-tab [title]="'RIS'">
                    <div class="kk-tab-content" *ngbTabContent>
                        <textarea class="citable-content" rows="20" [style.width.ch]="refworksWidth" [(ngModel)]="refworksReference"></textarea>
                    </div>
                </ngb-tab>

                <ngb-tab *ngIf="showTab" [title]="'M'">  <!-- show only for clippings -->
                        <div class="kk-tab-content" *ngbTabContent>
                            <textarea class="citable-content" rows="20" [style.width.ch]="marcWidth" [(ngModel)]="marcReference"></textarea>
                            <br>
                            <div style="color:darkorchid">(beta)</div>
                        </div>
                        
                </ngb-tab>

            </ngb-tabset>
        </div>
    `,
    styleUrls: [
        "../overlay-tabs.scss",
        "./citation-info.scss"
    ]
})
export class CitationInfoComponent implements OnInit {


    constructor(private $translate: TranslateService,
                public displayService: DisplayService,
                public router: Router) {

        // TODO authors need to be in alphabetical order by surname
        // TODO authors are formatted differently in Finnish and English/Swedish wikipedia pages
        // Finnish style: Tekijä = Reyes-Prieto, Adrian; Weber Andreas P.M.; Bhattacharya, Debashish
        // Eng style:
        // | last1 = Bailey | first1 = David H.
        // | author1-link = David H. Bailey
        // | last2 = Borwein | first2 = Peter
        // | author2-link = Peter Borwein
        // Swe style:
        // |efternamn=Bergman|förnamn=Gösta
    }

    private _citationInfo: CitationInfo;
    private _currentPageNumber: number;
    public showTab: boolean;

    wikipediaLocales = ['FI', 'SV', 'EN'];
    private _wikipediaLocale = 'FI';

    textualReference: string;
    wikipediaReference: string;
    bibtexReference: string;
    refworksReference: string;
    marcReference: string;

    textualWidth: number;
    wikiWidth: number;
    bibtexWidth: number;
    refworksWidth: number;
    marcWidth: number;

    get citationInfo() {
        return this._citationInfo;
    }

    @Input() set citationInfo(value: CitationInfo) {
        this._citationInfo = value;
        this.refreshData();
    }

    get currentPageNumber() {
        return this._currentPageNumber;
    }

    @Input() set currentPageNumber(value: number) {
        this._currentPageNumber = value;
        this.refreshData();
    }

    ngOnInit() {
        this.showTab = this.router.url.includes('/articles/');
    }


    get wikipediaLocale() {
        return this._wikipediaLocale;
    }

    set wikipediaLocale(value: string) {
        this._wikipediaLocale = value;
        this.refreshData();
    }



    checkIfArticlesRoute(): boolean {
        // Simplified Regex matching paths like /teos/binding/{any}/articles/
        // It now clearly looks for a string between '/binding/' and '/articles/'
        // /teos/binding/1911588/articles/81286900
        const pattern = /^\/teos\/binding\/\d+\/articles\/\d+$/;

        // todo. above  regex gives correct results, console.log(this.router.url);

        return pattern.test(this.router.url);
    }

    private formatDate(date: LocalDate, pattern: string): string {
        if (date == null)
            return '';

        return DateTimeFormatter.ofPattern(pattern).format(date);
    }

    private formatLongMonthYear(date: LocalDate): string {
        if (date == null)
            return '';

        const jsDate = convert(date).toDate();
        return jsDate.toLocaleDateString('en-GB', {month: 'long', year: 'numeric'}); // "December 2012"
    }

    private formatDayLongMonthYear(date: LocalDate): string {
        if (date == null)
            return '';
        const jsDate = convert(date).toDate();
        return jsDate.toLocaleDateString('en-GB', {month: 'long', year: 'numeric', day: 'numeric'}); // "20 December 2012"
    }

    selectWikipediaLocale(locale: string) {
        this.wikipediaLocale = locale;
    }

    private refreshData(): void {
        this.textualReference = this.createTextualReference();
        this.bibtexReference = this.createBibtexReference();
        this.wikipediaReference = this.createWikipediaReference();
        this.refworksReference = this.createRISReference();
        this.marcReference = this.createMARCReference();

        this.textualWidth = calculateWidth(this.textualReference, true);
        this.wikiWidth = calculateWidth(this.wikipediaReference, true);
        this.bibtexWidth = calculateWidth(this.bibtexReference, true);
        this.refworksWidth = calculateWidth(this.refworksReference, true);
        this.marcWidth = calculateWidth(this.marcReference, true);

    }

    private getRISLastPageLine(): string {
        const firstPage = this.getFirstPage();
        const lastPage = this.getLastPage();
        return lastPage && (firstPage !== lastPage) ? "\nEP  - " + lastPage : null;
    }

    private getUrnPart(): string {
        return this.citationInfo.issn;
    }

    private basicCitationFirstLine(): string {
        const info = this.citationInfo;
        const parts = [info.bindingTitle];

        // full date for papers, just year for books
        if (info.rISType !== 'BOOK') {
            parts.push(info.localizedPublishingDate);
        } else {
            parts.push(this.formatDate(info.publishingDate, 'yyyy'));
        }


        if (info.issue) {
            parts.push(this.$translate.instant("citation.issue.abbr") + " " + info.issue);
        }

        if (info.rISType !== 'BOOK') {
            parts.push(this.$translate.instant("citation.page.abbr") + " " + this.getPageRange());
        } else {
            parts.push(this.$translate.instant("citation.page.abbr") + " XX");
        }

        return parts.join(", ");
    }

    private getPageRange(): string | number {
        return this.citationInfo.pageRangeAsString || this.currentPageNumber;
    }

    private getFirstPage(): number {
        const range = this.citationInfo.pageRange;
        return (range && range.firstPage) || this.currentPageNumber;
    }

    private getLastPage(): number {
        const range = this.citationInfo.pageRange;
        return range && range.lastPage;
    }

    private uriWithPage(): string {
        return this.citationInfo.uri + "?page=" + this.getFirstPage();
    }

    private createTextualReference(): string {
        if (!this.citationInfo) return null;

        return [
            this.basicCitationFirstLine(),
            (this.citationInfo.rISType !== 'BOOK') ? this.uriWithPage() : this.citationInfo.fullUrn,
            this.$translate.instant('citation.source-organization'),
            this.$translate.instant('citation.accessdate')+':'+formatEuropeanDate(this.citationInfo.accessDate),
            ''
        ].join('\n');
    }

    private createWikipediaReference(): string {
        switch (this.wikipediaLocale) {
            case 'FI':
                return this.createFinnishWikipediaReference();
            case 'SV':
                return this.createSwedishWikipediaReference();
            case 'EN':
                return this.createEnglishWikipediaReference();
            default:
                throw new Error('unknown wikipedia locale ' + this.wikipediaLocale);
        }
    }

    private createFinnishWikipediaReference(): string {
        const info = this.citationInfo;
        if (!info) return null;

        if (info.rISType !== 'BOOK') {
            return [
                '<ref>{{',
                '    Lehtiviite',
                '    | Otsikko = ' + info.articleTitle,
                '    | Julkaisu = ' + info.bindingTitle,
                '    | Ajankohta = ' + formatEuropeanDate(info.publishingDate),
                '    | Numero = ' + info.issue,
                '    | Sivut = ' + this.getPageRange(),
                '    | www = ' + this.uriWithPage(),
                '    | www-teksti = Kansalliskirjasto',
                '    | Viitattu = ' + formatEuropeanDate(info.accessDate),
                '}}</ref>',
                ''
            ].join('\n');
        } else {
            return [
                '<ref name="xx">{{',
                '    Kirjaviite',
                '    | Nimeke = ' + info.bindingTitle,
                '    | Vuosi = ' + this.formatDate(info.publishingDate, 'yyyy'),
                '    | Julkaisija = ' + info.publisher,
                '    | Sivu = ' + this.getPageRange(),
                '    | Selite = ' + info.articleTitle,
                '    | www = ' + info.fullUrn,
                '    | www-teksti = Kansalliskirjasto',
                '    | Viitattu = ' + formatEuropeanDate(info.accessDate),
                '}}</ref>',
                ''
            ].join('\n');

        }
    }

    private createSwedishWikipediaReference(): string {
        const info = this.citationInfo;
        if (!info) return null;

        if (info.rISType !== 'BOOK') {

            return [
                '<ref>{{',
                '    tidningsref',
                '    | rubrik = ' + info.articleTitle,
                '    | tidning = ' + info.bindingTitle,
                '    | datum = ' + formatEuropeanDate(info.publishingDate),
                '    | nummer = ' + info.issue,
                '    | sid = ' + this.getPageRange(),
                '    | url = ' + this.uriWithPage(),
                '    | hämtdatum = ' + formatEuropeanDate(info.accessDate),
                '}}</ref>',
                ''
            ].join('\n');
        } else {

            // https://sv.wikipedia.org/wiki/Mall:Bokref
            return [
                '<ref>{{',
                '    bokref',
                '    | titel = ' + info.bindingTitle,
                '    | datum = ' + this.formatDate(info.publishingDate, 'yyyy'),
                '    | utgivare = ' + info.publisher,
                '    | övriga = ' + info.articleTitle,
                '    | sid = ' + this.getPageRange(),
                '    | url = ' + info.fullUrn,
                '    | hämtdatum = ' + formatEuropeanDate(info.accessDate),
                '}}</ref>',
                ''
            ].join('\n');

        }
    }

    private createEnglishWikipediaReference(): string {
        const info = this.citationInfo;
        if (!info) return null;

        if (info.rISType !== 'BOOK') {
            return [
                '<ref>{{',
                '    citation',
                '    | title = ' + info.articleTitle,
                '    | newspaper = ' + info.bindingTitle,
                '    | date = ' + formatUSDate(info.publishingDate),
                '    | issue = ' + info.issue,
                '    | pages = ' + this.getPageRange(),
                '    | url = ' + this.uriWithPage(),
                '    | accessdate = ' + formatUSDate(info.accessDate),
                '}}</ref>',
                ''
            ].join('\n');
        } else {
            return [
                '<ref>{{',
                '    cite book',
                '    | quote = ' + info.articleTitle,
                '    | title = ' + info.bindingTitle,
                '    | publisher = ' + info.publisher,
                '    | date = ' + this.formatDate(info.publishingDate, 'yyyy'),
                '    | page = ' + this.getPageRange(),
                '    | url = ' + info.fullUrn,
                '    | accessdate = ' + formatUSDate(info.accessDate),
                '}}</ref>',
                ''
            ].join('\n');
        }
    }

    private createBibtexReference(): string {
        const info = this.citationInfo;
        if (!info) return null;

        if (info.rISType !== 'BOOK') {

            return [
                '@article{xxxxxx,',
                '    title = {' + bibtexEscape(info.articleTitle) + '},',
                '    journal = {' + bibtexEscape(info.bindingTitle) + '},',
                '    number = {' + info.issue + '},',
                '    month = {' + englishMonthName(info.publishingDate) + ' ' + this.formatDate(info.publishingDate, 'dd') + '},',
                '    year = {' + (info.publishingDate && info.publishingDate.year()) + '},',
                '    ISSN = {' + info.issn + '},',
                '    pages = {' + this.getPageRange() + '},',
                '    keywords = {' + info.keywords.join(", ") + '},',
                '    onlineversion = {' + this.uriWithPage() + '}',
                '    urldate = {' + formatEuropeanDate(info.accessDate) +'}',
                '}',
                ''
            ].join('\n');
        } else {

            return [
                '@book{bookxx,',
                '    note = {' + bibtexEscape(info.articleTitle) + '},',
                '    title = {' + bibtexEscape(info.bindingTitle) + '},',
                '    year = {' + this.formatDate(info.publishingDate, 'yyyy') + '},',
                // '    pages = {' + this.getPageRange() + '},',
                '    pages = { XX },',
                '    publisher = {' + bibtexEscape(info.publisher) + '},',
                '    keywords = {' + info.keywords.join(", ") + '},',
                // '    onlineversion = {' + this.uriWithPage() + '}',
                '    onlineversion = {' + this.citationInfo.fullUrn + '}',
                '    urldate = {' + formatEuropeanDate(info.accessDate) +'}',
                '}',
                ''
            ].join('\n');

        }
    }

    private createRISReference(): string {
        const info = this.citationInfo;
        if (!info) return null;

        const keywordLines = _.map(info.keywords, kw => "\nKW  - " + kw).join("\n") || '';
        const lastPageLine = this.getRISLastPageLine() || '';
        const firstPage = this.getFirstPage() || '';

        let uriloc = this.uriWithPage();
        let maintitle = info.articleTitle;


        if (info.rISType === 'BOOK') {
            uriloc = this.citationInfo.fullUrn;
            maintitle = info.bindingTitle;
        }

        // 'M1  - ' + info.issue,  only in newsp.


        return [
            'TY  - ' + info.rISType,
            'ID  - ' + info.id,
            'T1  - ' + maintitle, // info.articleTitle
            'T2  - ' + info.bindingTitle,
            'PY  - ' + this.formatDate(info.publishingDate, 'yyyy'),
            `SP  - ${firstPage}`,
            'PB  - ' + info.publisher,
            'PP  - ' + info.publishingPlace,
            'SN  - ' + info.issn,
            'LA  - ' + info.rISLanguage,
            'U1  - ' + this.$translate.instant('citation.source-organization'),
            'Y2  - ' + this.formatDayLongMonthYear(info.accessDate),
            'UR  - ' + uriloc, // this.uriWithPage(),
            'ER  - '
        ].join('\n');
    }


    private createMARCReference(): string {
        const info = this.citationInfo;
        if (!info) return null;

        let keywords;
        let marcdatares;
        let d960s;

        keywords = keywordsto655s(info.keywords);
        d960s = keywordsto960s(info.keywords);

        // '008/00-39       191122r20222019fi |||| o     |0||0dfin| ',
        const marcbegin = [
            'Leader/00-23    00000nam a2200000 i 4500',
            '007/00-13       cr||||||||||||',
            '041       0#    $a' + info.rISLanguage
        ].join('\n');

        if (info.keywords != null) {
            marcbegin.concat('100       1#    $aTBD' + "$ekirjoittaja.\n");
        }

        // 773g needs to be different in books.
        const marccore: string = [
            '\n245       10    $a' + info.articleTitle,
            '300       ##    $a1 verkkoaineisto',
            '336       ##    $ateksti$btxt2rdacontent',
            '337       ##    $atietokonekäyttöinen$bc2rdamedia'].join('\n');

        // const marcdata1 = marcdata.concat(marcdata2);
        let marclinks = '';

        if (info.rISType ==='BOOK') {

            const marc773 : string = [
                '\n773       0#    7nnas$t' + bibtexEscape(info.bindingTitle) + '$o' + info.fullUrn + '$g'
                + this.formatDate(info.publishingDate, 'yyyy') + ', sivut ' + this.getPageRange()
                    ].join('\n');

            marclinks = marclinks.concat(marc773);

        } else {
            const marc773 : string = [
                '\n773       0#    7nnas$t' + bibtexEscape(info.bindingTitle) + '$o' + info.issn + '$g'
                + this.formatDate(info.publishingDate, 'd') + '.'
                + this.formatDate(info.publishingDate, 'M') + '.'
                + this.formatDate(info.publishingDate, 'yyyy') + ' : ' + info.issue + ', sivut ' + this.getPageRange(),
            ].join('\n');

            marclinks = marclinks.concat(marc773);
        }

        marcdatares = marcbegin.concat(marccore, marclinks, keywords,
            '856       40    $u' + this.uriWithPage() + '$ylinkki verkkoaineistoon',
            d960s);

        return marcdatares;

    }
}


const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function keywordsto960s (data: any[]) : string {

    let res = "";
    const alku  = "960       ##    $a";

    const valid960s = ["vekisu","Venäläinen käännöskirjallisuus","käännösproggis2019"];

    let nom;
    for (nom of data) {
        if (valid960s.indexOf(nom) >-1) {
            res =  res + alku + nom + "\n";
        }
    }

    res =  res + alku + "ARTO" + "\n";

    return "\n"+ res;
}

function keywordsto655s (data: any[]) : string {

    // tslint:disable-next-line:one-variable-per-declaration
    let res = "";
    // let nom = "";
    const alku  = "650       #7    $a";

    let nom;
    for (nom of data) {
        res =  res + alku + nom + "\n";
    }

    return "\n"+ res;
}

function englishMonthName(date: LocalDate): string {
    if (date == null)
        return '';
    else
        return monthNames[date.monthValue() - 1];
}

/** Increase the textarea's width by this amount of chars, just in case. */
const safetyPadding = 3;
/** Text wrap breakpoint. */
const TEXT_WRAP_BREAKPOINT = 90;

function calculateWidth(value: string, wrap: boolean) {
    const text = value || '';
    const maxLength = (wrap) ? (Math.min(getMaxLength(text), TEXT_WRAP_BREAKPOINT)) : getMaxLength(text);
    return maxLength + safetyPadding;
}

function getMaxLength(text: string) {
    return _.maxBy(text.split("\n"), l => l.length).length;
}
