import {Component, Inject, Input, OnDestroy} from "@angular/core";
import {FormControl} from "@angular/forms";
import {debounceTime, map, switchMap, take} from "rxjs/operators";
import {BindingInternalSearchRow, BindingSearchRestEndpoint, DebugHighlighterType, Dictionary, InsideBindingSearchResult, PageRequestSource} from "../../apina-digiweb";
import {BINDING_VIEW, ICurrentBindingView, PageRequest} from "../../binding/types";
import {BehaviorSubject, combineLatest, merge, Observable, of, Subscription} from "rxjs";
import {mapToList} from "../../utils/dictionary-utils";
import {MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {PaginatorI18n} from "../account/account-search-history.component";
import {Paging} from "../../utils/paging-utils";
import {DisplayService} from "../display.service";
import {isArray} from "lodash";

@Component({
    selector: "app-search-inside-binding",
    template: `
        <app-sidebar>
            <app-sidebar-header [titleKey]="'clipping.toolbar.search-inside'" (closeSidebar)="cbv.toggleSearch()"></app-sidebar-header>
            
            <app-sidebar-content>
                
                <i class="fa fa-search float-right" (click)="toggleDebug()"></i>
                
                <div *ngIf="debug">
                    <label>Highlighter
                    <select [formControl]="highlighter">
                        <option *ngFor="let hl of hlTypes" [value]="hl">{{hl}}</option>
                    </select>
                    </label>
                </div>
                
                <label translate>form.search.terms</label>
                <div class="kk-input-effects">
                    <input type="text" class="form-control" [formControl]="textInput" autofocus />
                    <div class="kk-input-indicator">
                        <app-progress-spinner [size]="'X-SMALL'" *ngIf="loading"></app-progress-spinner>
                    </div>   
                </div>
                
                <div class="d-flex justify-content-between mt-2">
                    <div class="d-flex">
                        <label class="col-form-label mr-2" translate>form.pages</label>
                        <input type="text" class="form-control mr-3" [style.max-width]="'7rem'" [formControl]="pageRange" />    
                    </div>
                    
                    <div>
                        <label class="col-form-label">{{'search-inside-binding.use-raw-text-index' | translate}} <input type="checkbox" [formControl]="exactWords"/></label>
                    </div>
                </div>
    
                <div *ngIf="textInput.value" class="mt-2">
                    <div *ngIf="hitCount$ | async as c" class="kk-bg-light">
                        <mat-paginator [length]="c"
                                       [pageSize]="pageSize"
                                       [pageSizeOptions]="sizeOptions"
                                       [pageIndex]="pageIndex"
                                       [showFirstLastButtons]="true"
                                       (page)="pagingChange($event)">
                        </mat-paginator>
                    </div>
                    
                    <div *ngIf="results$ | async as rb" class="results kk-bg-light" [style.maxHeight]="maxHeight | async">
                        <a *ngFor="let row of rb" class="result" (click)="gotoPage(row.pageNumber)">
                            <div class="highlight-fragment" [innerHTML]="hl" *ngFor="let hl of row.highlights"></div>
                            <div class="page-number">{{row.pageNumber}}</div>
                        </a>
    
                        <div *ngIf="rb.length === 0" class="no-results">
                            <span class="badge badge-danger" [style.font-size]="'1rem'" translate>query.result.no-results</span>
                        </div>    
                    </div>
                </div>

            </app-sidebar-content>
        </app-sidebar>
    `,
    styleUrls: [
        "./search-inside-binding.scss"
    ],
    providers: [
        {provide: MatPaginatorIntl, useClass: PaginatorI18n}
    ]
})
export class SearchInsideBindingComponent implements OnDestroy {

    @Input() bindingId: number;
    
    debug = false;
    loading = true;
    
    textInput = new FormControl();
    pageRange = new FormControl();
    exactWords = new FormControl();
    highlighter = new FormControl();
    hlTypes = Object.keys(DebugHighlighterType);
    
    query$: Observable<string> = this.textInput.valueChanges.pipe(debounceTime(500));
    
    results$ = new BehaviorSubject<UiRow[] | null>(null);
    hitCount$ = new BehaviorSubject<number>(null);

    pageIndex = 0;
    pageSize = 10;
    readonly minPageSize = 10;
    sizeOptions = [this.minPageSize, 100, 1000];

    paging$ = new BehaviorSubject<Paging>(new Paging(1, this.pageSize));

    offsetTop: Observable<number>;
    maxHeight: Observable<string>;
    readonly TOOLBAR_MARGIN = 10;
    
    // Padding including the height of the static parts of the component itself and some extra space for pager.
    // TODO detect automatically
    readonly EXTRA_PADDING = 240;
    
    private sub = new Subscription();
    
    constructor(private bindingSearchRestEndpoint: BindingSearchRestEndpoint,
                displayService: DisplayService,
                @Inject(BINDING_VIEW) public cbv: ICurrentBindingView) {

        this.offsetTop = displayService.headerVisibleHeight.pipe(map(h => h + this.TOOLBAR_MARGIN));
        this.maxHeight = displayService.headerVisibleHeight.pipe(map(h => `calc(100vh - ${h + this.TOOLBAR_MARGIN * 2 + this.EXTRA_PADDING}px)`));

        // set initial text box value from url
        this.sub.add(cbv.searchTerms$.pipe(take(1)).subscribe(terms => {
            this.textInput.setValue(terms.join(" "));
        }));
        
        const formState: Observable<MyFormState> = combineLatest([this.query$, this.exactWords.valueChanges, this.pageRange.valueChanges, cbv.loadedPageNumber$, this.paging$, this.highlighter.valueChanges])
            .pipe(map(([query, exact, pages, page, paging, hl]) => {
                return {
                    query,
                    exact,
                    pages,
                    page,
                    paging,
                    hl
                }
            }));

        this.sub.add(formState.pipe(
            switchMap((f) => {
                if (f.query && f.query.length > 0) {
                    return merge(
                        of(null), // fire event that we started fetching the results
                        this.bindingSearchRestEndpoint.searchInsideBinding({
                            bindingId: this.bindingId,
                            query: f.query,
                            exactWords: f.exact,
                            currentPage: f.page,
                            pages: f.pages
                        }, f.paging.offset, f.paging.pageSize, f.hl))
                } else {
                    return of([])
                }
            })
        ).subscribe((result: InsideBindingSearchResult | null | []) => {
            if (result == null) {
                this.loading = true;
            } else if (isArray(result)) {
                this.results$.next([]);
                this.hitCount$.next(0);
                cbv.setSearchTerms([]);
                this.loading = false;
            } else {
                const uiRows = result.pageRows.map(a => this.convert(a));
                this.results$.next(uiRows);
                this.hitCount$.next(result.totalRows);

                const allResponseTerms = this.getTermsOnCurrentPage(result);
                cbv.setSearchTerms(allResponseTerms);
                this.loading = false;
            }
        }));

        // trigger initial value
        this.exactWords.setValue(false);
        this.highlighter.setValue(DebugHighlighterType.AUTO);
        this.textInput.setValue(this.textInput.value);
        this.pageRange.setValue("");
    }
    
    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    convert(row: BindingInternalSearchRow): UiRow {
        return {
            terms: row.terms,
            highlights: this.getHighlightsAsHtml(row.textHighlights), 
            pageNumber: row.pageNumber
        }
    }

    /**
     * We expect them all to be about the text, so we can ignore the keys.
     */
    getHighlightsAsHtml(textHighlights: Dictionary<string[]>): string[] {
        return mapToList(textHighlights, (k, fragments) => {
            if (fragments.length > 0) {
                return "..." + fragments.join("...") + "...";
            } else
                return null;
        }).filter(s => s !== null);
    }

    private getTermsOnCurrentPage(result: InsideBindingSearchResult): string[] {
        const distinctTerms: any = {};
        for (const terms of result.currentPageResults.map(a => a.terms)) {
            for (const term of terms) {
                distinctTerms[term] = true;
            }
        }
        return Object.keys(distinctTerms);
    }
    
    gotoPage(page: number) {
        this.cbv.goToPage(new PageRequest(page, PageRequestSource.SEARCH_INSIDE_BINDING));
    }

    pagingChange($event: PageEvent) {
        this.pageIndex = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.paging$.next(Paging.from($event));
    }
    
    toggleDebug() {
        this.debug = !this.debug;
    }
}

interface UiRow {
    pageNumber: number;
    terms: string[];
    highlights: string[];
}

interface MyFormState {
    query: string,
    exact: boolean,
    pages: string,
    page: number,
    paging: Paging,
    hl: DebugHighlighterType
}