import {Component, Input} from "@angular/core";

export enum ResultType { BINDING = "BINDING", COMPONENT_PART = "COMPONENT_PART" }

@Component({
    selector: "app-search-result-count-badge",
    template: `
        <div>
            <span class="badge badge-pill {{getType()}}">{{total}}</span> 
        </div>
    `,
    styles: [
        `:host { display: inline-block; }`,
        `.badge { font-size: 1rem; margin-right: 1ex; }`
    ]
})
export class SearchResultCountBadgeComponent {
    @Input() total: number;
    
    @Input() resultType: ResultType | string = ResultType.BINDING;
    
    getType() {
        switch (this.resultType) {
            case ResultType.BINDING:
            default:
                return 'badge-secondary';
            case ResultType.COMPONENT_PART:
                return 'badge-primary';
        }
    }
}
