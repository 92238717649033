import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'app-sidebar-header',
    template: `
        <div class="sidebar-header">
            <div class="d-flex align-items-baseline">
                <span class="sidebar-title-wrapper">
                    <span class="sidebar-title">{{titleKey | translate}}</span>
                    <span class="sidebar-title-extras"><ng-content></ng-content></span>
                </span>
                <button mat-icon-button (click)="closeSidebar.emit()" class="sidebar-close"><i class="fa fa-times"></i></button>
            </div>
        </div>
    `,
    styleUrls: [
        "./sidebar-header.scss"
    ]
})
export class SidebarHeaderComponent {
    @Input() titleKey: string;
    @Output() closeSidebar = new EventEmitter<void>();
}