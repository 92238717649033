import {Component} from "@angular/core";
import {OpenDataRestEndpoint, OpenDataUsageReferenceListDTO} from "../../apina-digiweb";


@Component({
    template: `
        <section class="container">
            <h2 translate>opendata.title</h2>

            <p [innerHTML]="'opendata.usage-listing.long-description' | translate"></p>

            <p>
                <a routerLink="/opendata/submit">
                    <button class="btn btn-kk-blue" translate>opendata.usage-listing.packages-link</button>
                </a>
            </p>
        </section>

        <section class="container">
            <h3 translate>opendata.usage-listing.title</h3>

            <div>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="table.tblth" translate>opendata.usage-listing.columns.description</th>
                        <th class="table.tblth" translate>opendata.usage-listing.columns.link</th>
                        <th class="table.tblth" translate>opendata.usage-listing.columns.created</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of items">
                        <td>{{item.description}}</td>
                        <td><a href="{{item.externalUrl}}" target="_blank" rel="noopener" (click)="increaseClickCount(item.id)">{{item.externalUrl}}</a></td>
                        <td>{{item.created | date : 'dd.MM.yyyy'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>
    `
})
export class OpendataListComponent {

    items: OpenDataUsageReferenceListDTO[];

    constructor(private api: OpenDataRestEndpoint) {
        this.refresh();
    }

    private refresh() {
        this.api.getAllItems().subscribe(response => {
            this.items = response;
        });
    }

    public increaseClickCount(id: number) {
        this.api.increaseClicks(id).subscribe(response => {
            this.refresh();
        });
    }
}
