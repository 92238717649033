import {ApinaConfig, ArticleSearchRestEndpoint, ArticleSearchResultsBatch, UserArticleSearchCriteria} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {ErrorService} from "../error.service";
import {excelFileName} from "../../search/excel-utils";
import {buildQueryString} from "../../utils/url";
import {AbstractUserArticleSearchComponent, PAGE_SIZE} from "./article-search.component";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoggingService} from "../logging.service";
import {TranslateService} from "@ngx-translate/core";
import {NavigationService} from "../navigation.service";
import {Observable} from "rxjs";
import {SearchService} from "./search.service";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {BasicInfoService} from "../basic-info.service";
import {IPopoverResult} from "../popover-picker/popover-picker-async.component";
import {map} from "rxjs/operators";
import {IPopoverItem} from "../popover-picker/popover-picker.component";

@Component({
    templateUrl: './article-search.html',
    styleUrls: [
        "./binding-search.scss",
        "./article-search.scss"
    ]
})
export class UserArticleSearchComponent extends AbstractUserArticleSearchComponent implements OnInit, OnDestroy {

    constructor($log: LoggingService,
                navigationService: NavigationService,
                articleSearchRest: ArticleSearchRestEndpoint,
                errorService: ErrorService,
                settingsService: SettingsService,
                $translate: TranslateService,
                private apinaConfig: ApinaConfig,
                searchService: SearchService,
                basicInfoService: BasicInfoService,
                breadcrumbs: BreadcrumbsService) {

        super($log, navigationService, articleSearchRest, errorService, settingsService, searchService, basicInfoService, $translate);
        // $location.path("/articles");

        breadcrumbs.setLocalizedText('main.links.user.articles');

        const self = this;

        super.keywordProvider = {
            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return articleSearchRest.searchGlobalKeywords({
                    query,
                    generalTypes: null,
                    selectedIds,
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: r.selectedIdResults.map(a => ({id: a, title: a})),
                        queryResults: r.queryResults.map(a => ({id: a, title: a})),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            }, matches(item: IPopoverItem): boolean {
                return true;
            }
        };
    }

    ngOnInit() {
        super.$onInit();

        this.articleSearchRest.getStaticReferenceData().subscribe(referenceData => {
            this.referenceData = referenceData;
            this.loading = false;
        }, this.errorService.showUnexpectedError.bind(this.errorService));

        this.collectedEnabled = true;
    }

    ngOnDestroy(): void {
        super.$onDestroy();
    }

    excelDownloadUrl(): string {
        const filename = excelFileName("articles", this.resultsCriteria.query, this.resultsCriteria.startDate, this.resultsCriteria.endDate);
        return `/search/download/user-articles/${filename}.xlsx` + buildQueryString({
            criteria: JSON.stringify(this.apinaConfig.serialize(this.resultsCriteria, "UserArticleSearchCriteria"))
        });
    }

    protected searchForPage(params: UserArticleSearchCriteria, offset: number): Observable<ArticleSearchResultsBatch> {
        if (!params)
            throw Error("params must not be empty, was: " + params);

        return this.articleSearchRest.searchArticles(params, offset, PAGE_SIZE);
    }
}
