import {
    ArticleSearchRestEndpoint,
    ArticleSearchResultsBatch,
    ArticleSearchStaticReferenceData,
    GeneralType,
    TitleInfo,
    UserArticleBrowseOrder,
    UserArticleRow,
    UserArticleSearchCriteria
} from "../../apina-digiweb";
import {IPopoverItem, PopoverFormatter} from "../popover-picker/popover-picker.component";
import {ErrorService} from "../error.service";
import {SettingsService} from "../settings.service";
import {predicateAnd} from "../../utils/predicates";
import {asArray, asArrayOrNull, asIntArray, asIntArrayOrNull, normalizeQuery, optionalDate, parseLegacyDates, valueOrDefault} from "../../search/request-param-utils";
import {formatISODate} from "../../utils/date";
import {LoggingService} from "../logging.service";
import {TranslateService} from "@ngx-translate/core";
import {NavigationService} from "../navigation.service";
import {Observable, Subscription} from "rxjs";
import {SearchService} from "./search.service";
import * as _ from "lodash";
import {BasicInfoService} from "../basic-info.service";
import {map} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {ProcessedUserArticleRow, ResultMode} from "./result/result-row";
import {IPopoverItemProvider} from "../popover-picker/popover-picker-async.component";

export const PAGE_SIZE = 20;
const MAX_AUTO_DISPLAY_OCR = 160;

const PARAM_QUERY = 'query';
const PARAM_REQUIRE_ALL_KEYWORDS = 'requireAllKeywords';
const PARAM_QUERY_TARGETS_OCR_TEXT = 'qOcr';
const PARAM_QUERY_TARGETS_METADATA = 'qMeta';
const PARAM_FUZZY = 'fuzzy';
const PARAM_START_DATE = 'startDate';
const PARAM_END_DATE = 'endDate';
const PARAM_TITLE = 'title';
const PARAM_ORDER_BY = 'orderBy';
const PARAM_CATEGORY_IDS = 'categoryId';
const PARAM_SUBJECT_IDS = 'subjectId';
const PARAM_LEGACY_SUBJECT_IDS = 'subjectIds';
const PARAM_KEYWORDS = 'keyword';
const PARAM_COLLECTIONS = 'collection';
const PARAM_LEGACY_KEYWORDS = 'keywords'; // from legacy URLs
const PARAM_LEGACY_EVENTS = 'events';
const PARAM_INCLUDE_COLLECTED = 'includeCollected';
export const PARAM_FORMATS = 'formats'; // XXX referenced from child view

const PARAMS_RESULT_MODE = "resultMode";

// Params for compatibility with the old search URLs
const LEGACY_PARAM_TITLE = 'titles';
const LEGACY_PARAM_DATES = 'dates';

const CLEAN_DEFAULT_PARAMS_FOR_URL = true;

const PARAM_DEFAULTS: {[key: string]: any} = {
    [PARAM_QUERY]: '',
    [PARAM_REQUIRE_ALL_KEYWORDS]: true,
    [PARAM_QUERY_TARGETS_OCR_TEXT]: true,
    [PARAM_QUERY_TARGETS_METADATA]: true,
    [PARAM_FUZZY]: false,
    [PARAM_TITLE]: '',
    [PARAM_ORDER_BY]: UserArticleBrowseOrder.CREATED_DESC,
    [PARAM_INCLUDE_COLLECTED]: false,
    page: 1
};

interface IYearRanged {
    firstYear: number;
    lastYear: number;
}


export abstract class AbstractUserArticleSearchComponent {

    viewModeControl = new FormControl("THUMB");

    /** Are we still in the progress of loading the component? */
    loading = true;

    /** Should we show help fields in the form? */
    showHelp = false;

    /** Reference data for displaying the form */
    referenceData: ArticleSearchStaticReferenceData;

    formatChoices$: Observable<IPopoverItem[]>;

    readonly titleProvider: IPopoverItemProvider;
    keywordProvider: IPopoverItemProvider;

    disableFormatSelection = false;

    /** Search criteria */
    criteria: UserArticleSearchCriteria;

    /**
     * Criteria used to fetch current search results, only updated when user triggers a new search.
     *
     * Storing this separately from UI-criteria allows us to page through current search results without altering the
     * query in case that user has changed them after the initial search.
     */
    resultsCriteria: UserArticleSearchCriteria;

    private _page = 1;

    results: ArticleSearchResultsBatch;
    processedRows: ProcessedUserArticleRow[];

    publicationFilter: (p: TitleInfo) => boolean = null;

    resultMode = ResultMode.THUMB;

    excelDownloadEnabled: boolean;

    /** Should we display the option to include collector user articles in the query. */
    collectedEnabled = false;
    
    /** For generic template */
    public allowDelete = false;

    readonly collectionProvider: IPopoverItemProvider;
    readonly collectionFormatter: PopoverFormatter = {
        selectedFormat: {
            showInfo1: true,
            trimTitle: true
        },
        unselectedFormat: {
            showInfo1: false
        }
    };
    
    private sub = new Subscription();

    protected constructor(protected $log: LoggingService,
                          private navigationService: NavigationService,
                          protected articleSearchRest: ArticleSearchRestEndpoint,
                          protected errorService: ErrorService,
                          private settingsService: SettingsService,
                          private searchService: SearchService,
                          private basicInfoService: BasicInfoService,
                          protected $translate: TranslateService) {

        this.formatChoices$ = basicInfoService.sortedGeneralTypes$.pipe(map(gt => searchService.formatGeneralTypes(gt)));
        this.excelDownloadEnabled = settingsService.commonOptions.excelDownloadEnabled;

        this.titleProvider = searchService.createTitleProvider(
            () => this.criteria.generalTypes,
            () => this.criteria.startDate?.year(),
            () => this.criteria.endDate?.year()
        );

        this.collectionProvider = searchService.createCollectionProvider();
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        const oldValue = this._page;
        this._page = value;
        if (value !== oldValue)
            this.executeSearch(null, false, true);
    }

    $onInit() {
        const params = this.navigationService.search;
        this.criteria = createCriteria(params);

        if (params.resultMode) {
            this.resultMode = params.resultMode;
        }
        this.viewModeControl.setValue(this.resultMode);
        this.sub.add(this.viewModeControl.valueChanges.subscribe((mode: ResultMode) => {
            this.setResultMode(mode);
        }));
        
        if (params.page)
            this.changePage(parseInt(params.page, 10));

        // Automatically execute search whenever we enter this page
        this.executeSearch(null, true);
    }

    $onDestroy() {
        this.sub.unsubscribe();
    }
    
    get pageSize() { return PAGE_SIZE; }

    get visibleResultSize(): number {
        if (!this.results) {
            return 0;
        }
        const resultsSize = this.results.totalResults;
        const maxSize = this.settingsService.commonOptions.searchMaxResults;
        return maxSize < resultsSize ? maxSize : resultsSize;
    }

    changePage(page: number) {
        // check for max search size
        const maxSize = Math.floor(this.settingsService.commonOptions.searchMaxResults / this.pageSize);

        if (page > maxSize) {
            this.page = maxSize;
            this.navigationService.setSearchParam("page", this.page);
        } else {
            this.page = page;
        }
    }

    /**
     * Stores active search criteria when search is executed. Active search is only updated when new search is executed, changes in criteria inputs
     * do not change the active criteria.
     */
    private storeSearchCriteria() {
        this.resultsCriteria = _.cloneDeep(this.criteria);
    }

    executeSearch(e?: Event, fromUrl = false, fromResults = false) {
        if (e) e.preventDefault();

        this.$log.debug("searching with parameters", this.criteria);

        if (!fromUrl && !fromResults) {
            this.page = 1;
        }

        if (!fromUrl && this.criteria.query !== null && this.criteria.query.trim().length > 0) {
            this.criteria.orderBy = UserArticleBrowseOrder.RELEVANCE;
        }

        // TODO this is probably too complex, refactor
        if (fromResults && this.resultsCriteria)
            // store orderBy so that it doesn't reset / "flicker" when user presses search again after changing it manually
            this.criteria.orderBy = this.resultsCriteria.orderBy;

        if (!fromResults || !this.resultsCriteria)
            this.storeSearchCriteria();

        if (!fromUrl)
            this.updateLocation();

        const offset = (this.page - 1) * PAGE_SIZE;

        if (this.results)
            this.results.rows = null;

        this.searchForPage(this.resultsCriteria, offset).subscribe(results => {
            this.results = results;
            this.processedRows = this.processRows(results.rows);
        });
    }

    private processRows(rows: UserArticleRow[]): ProcessedUserArticleRow[] {
        return rows.map(originalRow => {
            const row: ProcessedUserArticleRow = Object.assign({}, originalRow) as any;
            row.highLights = this.searchService.getHighlightsAsHtml(row.textHighlights);
            row.displayOCR = !this.hasHighlights(row) || (row.text.length === 0 || row.text[0].length <= MAX_AUTO_DISPLAY_OCR);
            return row;
        });
    }

    protected updateLocation() {
        if (this.resultsCriteria != null) {
            const params = paramsFromCriteria(this.resultsCriteria);

            params.page = this.page;
            params[PARAMS_RESULT_MODE] = this.resultMode;

            if (CLEAN_DEFAULT_PARAMS_FOR_URL) {
                this.removeDefaultQueryParameters(params);
            }

            this.navigationService.search = params;
        }
    }

    // XXX copied from binding-search.component
    private removeDefaultQueryParameters(params: {[key: string]: any}) {
        Object.keys(params).forEach((key: string) => {
            const val = params[key];

            if (val == null || (val instanceof Array && val.length === 0) || PARAM_DEFAULTS[key] === val)
                delete params[key];
        });
    }

    toggleHelp(e?: Event) {
        if (e) e.preventDefault();
        this.showHelp = !this.showHelp;
    }

    setResultMode(resultMode: ResultMode) {
        this.resultMode = resultMode;
        this.navigationService.setSearchParam(PARAMS_RESULT_MODE, this.resultMode);
    }

    public updateFiltersDependentOnDates() {
        this.publicationFilter = this.createDateFilter();
    }

    private createDateFilter(): (o: IYearRanged) => boolean {
        // TODO: copy-paste from serial-publication-search.directive.ts
        // FIXME: doesn't handle null years
        const startDate = this.resultsCriteria.startDate;
        const endDate = this.resultsCriteria.endDate;
        const startYear = startDate && startDate.year();
        const endYear = endDate && endDate.year();

        return predicateAnd(
            startYear ? ((o: IYearRanged) => o.lastYear == null || o.lastYear >= startYear) : null,
            endYear ? ((o: IYearRanged) => o.firstYear == null || o.firstYear <= endYear) : null);
    }

    protected abstract searchForPage(params: UserArticleSearchCriteria, offset: number): Observable<ArticleSearchResultsBatch>;

    public hasHighlights(row: ProcessedUserArticleRow) {
        return row.highLights.length > 0;
    }

    public displayOCR($event: Event, row: ProcessedUserArticleRow) {
        $event.stopPropagation();
        $event.preventDefault();

        row.displayOCR = true;
    }
}

function createCriteria(params: any): UserArticleSearchCriteria {
    const legacyDates = parseLegacyDates(params[LEGACY_PARAM_DATES], true);

    function vod(key: string): any {
        return valueOrDefault(key, params, PARAM_DEFAULTS);
    }

    return {
        query: normalizeQuery(vod(PARAM_QUERY)),
        queryTargetsOcrText: vod(PARAM_QUERY_TARGETS_OCR_TEXT),
        queryTargetsMetadata: vod(PARAM_QUERY_TARGETS_METADATA),
        requireAllKeywords: vod(PARAM_REQUIRE_ALL_KEYWORDS),
        fuzzy: vod(PARAM_FUZZY),
        generalTypes: asArray(params[PARAM_FORMATS] || '') as GeneralType[],
        startDate: optionalDate(params[PARAM_START_DATE]) || (legacyDates != null && legacyDates.start) || null,
        endDate: optionalDate(params[PARAM_END_DATE]) || (legacyDates != null && legacyDates.end) || null,
        categoryIds: asIntArray(params[PARAM_CATEGORY_IDS]),
        subjectIds: asIntArrayOrNull(params[PARAM_SUBJECT_IDS]) || asIntArray(params[PARAM_LEGACY_SUBJECT_IDS]),
        titles: asArray(params[PARAM_TITLE] || params[LEGACY_PARAM_TITLE]),
        keywords: asArrayOrNull(params[PARAM_KEYWORDS] || asArrayOrNull(params[PARAM_LEGACY_KEYWORDS]) || asArray(params[PARAM_LEGACY_EVENTS])),
        collections: asIntArray(params[PARAM_COLLECTIONS]),
        exactCollectionMaterialType: false,
        orderBy: vod(PARAM_ORDER_BY),
        includeCollected: vod(PARAM_INCLUDE_COLLECTED),
        onlyCollected: false
    };
}

function paramsFromCriteria(criteria: UserArticleSearchCriteria): any {
    return {
        [PARAM_QUERY]: criteria.query,
        [PARAM_REQUIRE_ALL_KEYWORDS]: criteria.requireAllKeywords,
        [PARAM_QUERY_TARGETS_OCR_TEXT]: criteria.queryTargetsOcrText,
        [PARAM_QUERY_TARGETS_METADATA]: criteria.queryTargetsMetadata,
        [PARAM_FUZZY]: criteria.fuzzy,
        [PARAM_FORMATS]: criteria.generalTypes,
        [PARAM_START_DATE]: formatISODate(criteria.startDate),
        [PARAM_END_DATE]: formatISODate(criteria.endDate),
        [PARAM_CATEGORY_IDS]: criteria.categoryIds,
        [PARAM_SUBJECT_IDS]: criteria.subjectIds,
        [PARAM_TITLE]: criteria.titles,
        [PARAM_KEYWORDS]: criteria.keywords,
        [PARAM_COLLECTIONS]: criteria.collections,
        [PARAM_ORDER_BY]: criteria.orderBy,
        [PARAM_INCLUDE_COLLECTED]: criteria.includeCollected
    };
}

