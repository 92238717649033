import {Component, Input} from "@angular/core";
import {ProcessedBindingSearchResultRow} from "./result-row";

@Component({
    selector: "app-search-result-text-thumb",
    template: `
        <div class="result-row">
            <a [href]="row.url" target="_blank" appStoreSession>
                <div class="media">
                    <div class="media-left text-with-thumb-image-container" *ngIf="showThumb">
                        <div style="display:inline-block; position:relative; min-height: 200px; padding-bottom: 5px;">
                            <img class="media-object text-with-thumb-image" [src]="row.thumbnailUrl" alt="" appErrorThumb>
                            <div class="pagenum2 text-center"><span *ngIf="row.pageNumber != 1" class="badge badge-secondary">{{row.pageNumber}}</span></div>
                        </div>
                    </div>
                    <div class="media-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="meta-data">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <div class="main-link-title link-colors">{{row.mainLinkTitle}}</div>
                                            <div class="belongs-to" *ngIf="row.belongsToTitle != null"><i class="fa fa-level-up"></i>{{row.belongsToTitle}}</div>
                                            <div *ngIf="row.authors != null">{{row.authorList}}</div>

                                            <span class="font-weight-bold"><span *ngIf="row.issue != null"><span translate>query.result.nro</span>&nbsp;{{row.issue}}&nbsp;</span>
                                                <span *ngIf="row.date != null">{{row.date | dateWithAccuracy:row.dateAccuracy}}</span>
                                            </span>
                                            <span *ngIf="row.publisher != null">, {{row.publisher}}</span>
                                            <span *ngIf="row.placeOfPublication != null">, {{row.placeOfPublication}}</span>
                                            <span *ngIf="row.pageNumber != null && !isAggregation">: <span translate>query.result.page</span> {{row.pageNumber}}
                                            </span>
                                            
                                        </div>
                                            <div *ngIf="row.importDate != null" placement="left-bottom" [ngbTooltip]="'form.import-date' | translate" class="import-date ml-auto" style="color: darkgray; overflow:hidden;text-overflow:ellipsis"><i class="fa-regular fa-box-archive fa-fw"></i>{{row.importDate | localizedDate}}  
                                            </div>
                                    </div>
                                    <div *ngIf="isAggregation && row.score">
                                        {{'query.result.page-hit-count' | translate}} {{row.score}}
                                    </div>
                                </div>
                                <div class="mt-3 content-data">
                                    <span *ngIf="!row.authorized" class="preview-text-unauthorized">
                                        <i class="fa fa-lock"></i>&nbsp;<span translate>query.result.preview-text-unauthorized</span>
                                    </span>
                                    <div class="preview" *ngIf="hasHighlights(row)">
                                        <div class="search-highlight-fragment" *ngFor="let hl of row.highLights" [innerHTML]="hl"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    `,
    styleUrls: [
        "./result-common.scss"
    ]
})
export class TextThumbResultComponent {
    @Input() showThumb: boolean;
    @Input() row: ProcessedBindingSearchResultRow;
    @Input() isAggregation: boolean;

    public hasHighlights(row: ProcessedBindingSearchResultRow) {
        return row.highLights.length > 0;
    }
}
