import {Injectable} from "@angular/core";

@Injectable()
export class PlatformService {
    
    constructor() {
        try {
            const ua = navigator.userAgent;
            this.iOSWithScrollingProblems = /(iPad|iPhone); CPU( iPhone)? OS (9|10|11|12|13|14)/.test(ua);
        } catch (e) {
            this.iOSWithScrollingProblems = false;
        }
    }

    /**
     * Older iPads don't like our current system of CSS grid + HammerJS.
     */
    readonly iOSWithScrollingProblems: boolean;
}