<app-progress-bar *ngIf="loading"></app-progress-bar>

<div class="search-form article-search" *ngIf="!loading">
    <div class="kk-bg-lightgray pt-3 pb-3">
        <section class="container" style="padding-top:15px;">
            <!-- hakulause -->
            <form #form="ngForm" (submit)="executeSearch()" class="form-horizontal">
    
                <div class="form-group row mb-0">
    
                    <div class="col-12 mb-3">
                        <div class="d-flex justify-content-between">
                            <input type="text" class="form-control" [(ngModel)]="criteria.query" name="query"  [attr.aria-label]="'form.search.term' | translate"
                                   appAutofocus appValidQuerySyntax [placeholder]="'form.search.term' | translate" style="z-index: 2">
    
                            <button class="btn btn-kk-blue btn-block ml-3" style="width: 9rem; max-width: 20%;" (click)="executeSearch($event)" [disabled]="form.invalid" [ngbTooltip]="'form.search.submit' | translate" [attr.aria-label]="'form.search.submit' | translate"><i class="fa fa-search fa-lg"></i></button>
                            <button type="button" class="btn btn-kk-gray ml-3" (click)="toggleHelp($event)" [ngbTooltip]="'search.tip.toggle' | translate" [attr.aria-label]="'search.tip.toggle' | translate"><i class="fa fa-question fa-lg"></i></button>
                        </div>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.term' | translate"></div>
                    </div>
                </div>
    
                <div class="form-group row">
                    <div class="col-12">
                        <div class="child-relationship" style="z-index: 1"><i></i></div>
    
                        <label class="checkbox-inline mr-3 mb-0">
                            <input type="checkbox" [(ngModel)]="criteria.requireAllKeywords"
                                   name="allKeywords"> {{'form.require-all' | translate}}
                        </label>
                        <label class="checkbox-inline mr-3 mb-0">
                            <input type="checkbox" [(ngModel)]="criteria.fuzzy" name="fuzzy"> {{'form.fuzzy' | translate}}
                        </label>
    
                        <span class="labelish mr-2" translate>form.query-targets.prefix</span>
                        <label class="checkbox-inline mr-3 mb-0">
                            <input type="checkbox" [(ngModel)]="criteria.queryTargetsOcrText" [disabled]="!criteria.queryTargetsMetadata" name="queryTargetsOcrText"> {{'form.query-targets-ocr-text' | translate}}
                        </label>
    
                        <label class="checkbox-inline mr-3 mb-0">
                            <input type="checkbox" [(ngModel)]="criteria.queryTargetsMetadata" [disabled]="!criteria.queryTargetsOcrText" name="queryTargetsMetadata"> {{'form.query-targets-metadata' | translate}}
                        </label>
    
                        <label class="float-right alert-warning" >
                            <span *ngIf="!showHelp" [innerHTML]="'clipping.tip' | translate"></span>
                        </label>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.options' | translate" ></div>
                    </div>
                </div>

                <div class="row dynamic-search-fields mb-0 align-items-center">
                    <!-- aineistotyyppi -->
                    <div class="form-group col-md-12 col-lg-6">
                        <div class="row">
                            <div class="col-md-12">
                                <app-popover-picker type='format' [(ngModel)]="criteria.generalTypes" [items]="formatChoices$ | async" name="formats"
                                                    [setDisabled]="disableFormatSelection"></app-popover-picker>
                            </div>
                        </div>
                        <div class="row" *ngIf="showHelp">
                            <div class="col-12">
                                <span class="help-block" [innerHTML]="'form.help.general-type' | translate"></span>
                            </div>
                        </div>
                    </div>
    
                    <!-- aikaväli -->
                    <div class="form-group col-md-12 col-lg-6">
                        <div class="d-flex justify-content-between">
                            <label class="col-form-label mr-3" translate>form.date-range</label>
    
                            <app-datepicker
                                    [(ngModel)]="criteria.startDate"
                                    (onChange)="updateFiltersDependentOnDates()"
                                    [normalize]="'start-of-year'"
                                    name="startdate"></app-datepicker>
    
                            <label class="col-form-label ml-3 mr-3">&mdash;</label>
    
                            <app-datepicker
                                    [(ngModel)]="criteria.endDate"
                                    (onChange)="updateFiltersDependentOnDates()"
                                    [normalize]="'end-of-year'"
                                    name="enddate"></app-datepicker>
    
                        </div>
    
                        <div class="d-flex justify-content-between" *ngIf="showHelp">
                            <div class="help-block" [innerHTML]="'form.help.date.start' | translate"></div>
    
                            <div class="help-block" [innerHTML]="'form.help.date.end' | translate"></div>
                        </div>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.date' | translate"></div>
                    </div>
                
                    <!-- nimeke -->
                    <div class="col-md-12">
                        <app-popover-picker-async type='title' [(ngModel)]="criteria.titles"
                                            [provider]="titleProvider"
                                            name="titles" [large]="true"></app-popover-picker-async>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.publication' | translate"></div>
                    </div>
    
                    <!-- avainsanat -->
                    <div class="col-lg-6">
                        <app-popover-picker-async type='keyword' [(ngModel)]="criteria.keywords" [provider]="keywordProvider" name="keywords"></app-popover-picker-async>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.article-keywords' | translate"></div>
                    </div>
    
                    <!-- artikkelityyppi -->
                    <div class="col-lg-6">
                        <app-popover-picker type='category' [(ngModel)]="criteria.categoryIds" [items]="referenceData.categories" name="categories"></app-popover-picker>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.article-type' | translate"></div>
                    </div>
    
                    <!-- aihe alue -->
                    <div class="col-lg-6">
                        <app-popover-picker type='subject' [(ngModel)]="criteria.subjectIds" [items]="referenceData.subjects" name="subjects"></app-popover-picker>
    
                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.article-subject' | translate"></div>
                    </div>

                    <!-- kokoelma -->
                    <div class="col-lg-6">
                        <app-popover-picker-async type='collection' [(ngModel)]="criteria.collections"
                                                  [provider]="collectionProvider"
                                                  [formatter]="collectionFormatter"
                                                  name="collections"></app-popover-picker-async>

                        <div *ngIf="showHelp" class="help-block" [innerHTML]="'form.help.collection' | translate"></div>
                    </div>
                    
                    <!-- Automaattisesti generoitujen leikkeiden rajaus -->
                    <div class="col-lg-6" *ngIf="collectedEnabled">
                        <label class="checkbox-inline mr-3 mb-0">
                            <input type="checkbox" [(ngModel)]="criteria.includeCollected" name="includeCollected"> {{'form.search.include-collected' | translate}}
                        </label>
                    </div>
                </div>
            </form>
        </section>
    </div>

    <section class="container">
        <app-progress-spinner *ngIf="results == null"></app-progress-spinner>
        <div *ngIf="results != null">
            <div class="col text-center mt-3" *ngIf="results.totalResults == 0">
                <div class="span12"><span class="badge badge-danger" style="font-size: 20px;" translate>query.result.no-results</span></div>
            </div>
            <div *ngIf="results.totalResults == 0 && results.queryValidity == 'INVALID'" class="col text-center mt-3">
                <span class="badge badge-info" style="font-size: 20px;" translate>query.result.invalid-query</span>
            </div>

            <div class="row" *ngIf="results.totalResults != 0">
                <div class="col-12 col-lg-6">
                    <div class="d-sm-flex justify-content-between align-items-center">
                        <app-search-result-count-badge [total]="results.totalResults" class="mb-3"></app-search-result-count-badge>

                        <ngb-pagination [page]="page"
                                [collectionSize]="visibleResultSize"
                                [pageSize]="pageSize"
                                (pageChange)="changePage($event)"></ngb-pagination>
                    </div>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="d-sm-flex justify-content-between align-items-center">
                        <div class="mb-3 d-flex align-items-center">
                            <mat-button-toggle-group name="displayMode" attr.aria-label="{{'browser.select-display-mode' | translate}}" [formControl]="viewModeControl">
                                <mat-button-toggle value="TEXT" title="{{'search.text.tooltip'|translate}}" aria-label="{{'search.text.tooltip'|translate}}"><i class="fa fa-align-justify"></i></mat-button-toggle>
                                <mat-button-toggle value="TEXT_WITH_THUMB" title="{{'search.thumbtext.tooltip'|translate}}" aria-label="{{'search.thumbtext.tooltip'|translate}}"><i class="fa fa-th-list"></i></mat-button-toggle>
                                <mat-button-toggle value="THUMB" title="{{'search.thumb.tooltip'|translate}}" aria-label="{{'search.thumb.tooltip'|translate}}"><i class="fa fa-th"></i></mat-button-toggle>
                            </mat-button-toggle-group>

                            <a *ngIf="excelDownloadEnabled" class="btn btn-kk-blue ml-3" [ngbTooltip]="'search.excel.tooltip' | translate" [href]="excelDownloadUrl()" rel="nofollow" [attr.aria-label]="'search.excel.tooltip' | translate"><i class="fa fa-file-excel-o"></i></a>
                        </div>

                        <div class="mb-3">
                            <select [(ngModel)]="resultsCriteria.orderBy" class="form-control input-sm" (change)="executeSearch($event, false, true)" name="orderBy">
                                <option value="RELEVANCE" translate>article.search.sort.relevance</option>
                                <option value="CREATED_DESC" translate>article.search.sort.creation_time.desc</option>
                                <option value="CREATED_ASC" translate>article.search.sort.creation_time.asc</option>
                                <option value="BINDING_TITLE" translate>article.search.sort.binding_title</option>
                                <option value="PUBLICATION_DATE_DESC" translate>article.search.sort.publication_date.desc</option>
                                <option value="PUBLICATION_DATE_ASC" translate>article.search.sort.publication_date.asc</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <app-article-search-results [items]="processedRows" [resultMode]="resultMode" [allowDelete]="allowDelete" (deleteRow)="deleteRow($event)"></app-article-search-results>

            <div *ngIf="results != null && results.totalResults != 0" class="d-flex justify-content-center">
                <ngb-pagination [page]="page"
                                [collectionSize]="visibleResultSize"
                                [pageSize]="pageSize"
                                (pageChange)="changePage($event)"></ngb-pagination>
            </div>
        </div>
    </section>
</div>
