import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {CollectionTreeNodeDTO} from "../../apina-digiweb";
import {NestedTreeControl} from "@angular/cdk/tree";
import {findCollectionWithAncestors} from "./collection-utils";

@Component({
    selector: "app-collection-tree",
    template: `
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="kk-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li class="mat-tree-node">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                    <a href="#" [ngClass]="{selected: selectedCollection == node}"
                       (click)="selectCollection($event, node)">{{node.name}}</a>
                </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li>
                    <div class="mat-tree-node">
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <a href="#" [ngClass]="{selected: selectedCollection == node}"
                           (click)="selectCollection($event, node)">{{node.name}}</a>
                        <span class="ml-2 text-muted">({{node.children?.length}})</span>
                    </div>
                    <ul [class.kk-tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    `,
    styleUrls: [
        "./collection-tree.scss"
    ]
})
export class CollectionTreeComponent {
    @Input() dataSource: MatTreeNestedDataSource<CollectionTreeNodeDTO>;

    /**
     * Only activated when id is selected from outside this component.
     */
    @Input() set selectedId(value: number) {
        if (value && value !== this.selectedCollection?.id)
            this.selectCollectionFromId(value);
        else
            this.selectedCollection = null;
    }
    
    @Output() selectNode = new EventEmitter<CollectionTreeNodeDTO>();
    
    treeControl = new NestedTreeControl<CollectionTreeNodeDTO>(node => node.children);

    selectedCollection: CollectionTreeNodeDTO = null;

    public hasChild = (_: number, node: CollectionTreeNodeDTO) => !!node.children && node.children.length > 0;

    public selectCollection($event: Event, collection: CollectionTreeNodeDTO) {
        $event.preventDefault();

        this.selectedCollection = collection;
        this.selectNode.emit(collection);
    }

    private selectCollectionFromId(id: number) {
        const result = findCollectionWithAncestors(this.dataSource.data, id);

        if (result != null)
            this.selectedCollection = result[0];

        for (const level of result) {
            this.treeControl.expand(level);
        }
    }
}